<template>
	<div class="inputter">
		<span class="hint">{{ hint }}</span>
		<input ref="inputter" type="text"
			@keydown.enter="onEnter"
			@keydown.up="onUp"
			@keydown.down="onDown"
		/>
	</div>
</template>

<style scoped>
.inputter {
	position: relative;
	width: 100%;
}
.inputter .hint {
	font-size: 20px;
}
.inputter input {
	position: absolute;
	top: -2px;
	left: 22px;
	right: 0px;
	height: 20px;
	background-color: transparent;
	outline: none;
	border: none;
	font-family: "Arcade";
	font-size: 20px;
	color: rgba(226, 225, 228, 1.0);
	text-shadow: 1px 1px 0px black;
	line-height: 20px;
}
</style>

<script>
const CommandHistory = [];
var cmdIndex = 0;

export default {
	name: "Inputter",
	props: ["hint"],
	methods: {
		focus () {
			this.$refs.inputter.focus();
			this.$refs.inputter.scrollIntoViewIfNeeded();
		},
		onEnter () {
			var cmd = this.$refs.inputter.value;
			if (!cmd) return;
			CommandHistory.push(cmd);
			cmdIndex = CommandHistory.length;
			this.$emit('onInput', cmd);
			this.$refs.inputter.value = "";
		},
		async onUp () {
			var next = cmdIndex - 1;
			if (next < 0) {
				return;
			}
			var cmd = CommandHistory[next];
			if (!cmd) return;
			cmdIndex = next;
			this.$refs.inputter.value = cmd;
			this.$refs.inputter.focus();
			await wait();
			this.$refs.inputter.selectionStart = cmd.length;
			this.$refs.inputter.selectionEnd = cmd.length;
		},
		async onDown () {
			var next = cmdIndex + 1;
			if (next > CommandHistory.length) {
				return;
			}
			var cmd = CommandHistory[next] || '';
			cmdIndex = next;
			this.$refs.inputter.value = cmd;
			this.$refs.inputter.focus();
			await wait();
			this.$refs.inputter.selectionStart = cmd.length;
			this.$refs.inputter.selectionEnd = cmd.length;
		},
		async appendValue (value) {
			this.$refs.inputter.value = this.$refs.inputter.value + value;
			this.$refs.inputter.focus();
			await wait();
			this.$refs.inputter.selectionStart = this.$refs.inputter.value.length;
			this.$refs.inputter.selectionEnd = this.$refs.inputter.value.length;
		}
	},
};
</script>