<template>
	<div ref="contents" v-for="line of cmd" class="cmdLine" @dblclick="onDBClick" @click="onClick"></div>
</template>

<style>
.console_container .console .resultList .cmdLine {
	position: relative;
	line-height: 20px;
}
.console_container .console .resultList .cmdLine > span {
	margin-left: 0px;
	line-height: 25px;
}
.console_container .console .resultList .cmdLine > span:first-child {
	margin-left: 0px !important;
}
.console_container .console .resultList .cmdLine span.user {
	margin-left: 5px;
	color: rgba(93, 190, 138, 1.0);
}
.console_container .console .resultList .cmdLine span.env {
	margin-left: 5px;
	color: rgba(210, 53, 125, 1.0);
}
.console_container .console .resultList .cmdLine span.path {
	margin-left: 5px;
	color: rgba(254, 215, 26, 1.0);
}
.console_container .console .resultList .cmdLine span.branch {
	margin-left: 5px;
	color: rgba(86, 152, 195, 1.0);
}
.console_container .console .resultList .cmdLine span.branch:before {
	content: "("
}
.console_container .console .resultList .cmdLine span.branch:after {
	content: ")"
}

.console_container .console .resultList .cmdLine span.error {
	color: rgba(238, 63, 77, 1.0);
}
.console_container .console .resultList .cmdLine span.warn {
	color: rgba(254, 215, 26, 1.0);
}
.console_container .console .resultList .cmdLine span.log {
	color: rgba(93, 190, 138, 1.0);
}

.console_container .console .resultList .cmdLine span.file {
	margin-left: 5px;
}
.console_container .console .resultList .cmdLine span.executable {
	position: relative;
	color: transparent;
	-webkit-text-fill-color: transparent;
	text-shadow: none;
}
.console_container .console .resultList .cmdLine span.executable:before {
	content: attr(text);
	position: absolute;
	width: 100%;
	top: -3px;
	left: 0;
	color: transparent;
	-webkit-text-fill-color: transparent;
	font-weight: bold;
	text-align: center;
	text-shadow: 1px 1px 1px black;
}
.console_container .console .resultList .cmdLine span.executable:after {
	content: attr(text);
	position: absolute;
	width: 100%;
	top: -3px;
	left: 0;
	background-image: linear-gradient(95deg, rgba(67, 178, 68, 1.0), rgba(238, 72, 99, 1.0));
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
	text-align: center;
	font-weight: bold;
}
.console_container .console .resultList .cmdLine span.folder {
	margin-left: 5px;
	color: rgba(97, 154, 195, 1.0);
}

.console_container .console .resultList .cmdLine a,
.console_container .console .resultList .cmdLine a:hover,
.console_container .console .resultList .cmdLine a:active,
.console_container .console .resultList .cmdLine a:visited {
	display: inline-block;
	color: rgba(99, 187, 208, 1.0);
	text-decoration: none;
	border-bottom: 1px dashed rgba(99, 187, 208, 1.0);
}
.console_container .console .resultList .cmdLine img.inner_image {
	object-fit: contain;
}
.console_container .console .resultList .cmdLine span.bold {
	position: relative;
	top: -5px;
	font-family: "Quantum";
	font-size: 0.7em;
	font-weight: bold;
	color: rgba(248, 195, 135, 1.0);
}
.console_container .console .resultList .cmdLine span.italic {
	position: relative;
	top: -4px;
	font-family: "AlphaSector";
	font-size: 0.7em;
}
.console_container .console .resultList .cmdLine span.under {
	border-bottom: 1px dashed rgba(226, 225, 228, 1.0);
}
.console_container .console .resultList .cmdLine span.big {
	position: relative;
	top: 5px;
	font-size: 2em;
	line-height: 25px;
}
.console_container .console .resultList .cmdLine span.large {
	position: relative;
	top: 10px;
	font-size: 3em;
	line-height: 40px;
}
.console_container .console .resultList .cmdLine span.huge {
	position: relative;
	top: 18px;
	font-size: 5em;
	line-height: 60px;
}
.console_container .console .resultList .cmdLine span.emphasis {
	position: relative;
	color: transparent;
	-webkit-text-fill-color: transparent;
	text-shadow: none;
}
.console_container .console .resultList .cmdLine span.emphasis:before {
	content: attr(text);
	position: absolute;
	width: 100%;
	top: -3px;
	left: 0;
	color: transparent;
	-webkit-text-fill-color: transparent;
	font-weight: bold;
	text-align: center;
	text-shadow: 1px 1px 1px black;
}
.console_container .console .resultList .cmdLine span.emphasis:after {
	content: attr(text);
	position: absolute;
	width: 100%;
	top: -3px;
	left: 0;
	background-image: linear-gradient(95deg, rgba(138, 188, 209, 1.0), rgb(91, 93, 163));
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
	text-align: center;
	font-weight: bold;
}

.console_container .console .resultList .cmdLine span.red {
	color: rgba(237, 90, 101, 1.0);
}
.console_container .console .resultList .cmdLine span.yellow {
	color: rgba(252, 211, 55, 1.0);
}
.console_container .console .resultList .cmdLine span.blue {
	color: rgba(49, 112, 167, 1.0);
}
.console_container .console .resultList .cmdLine span.green {
	color: rgba(27, 167, 132, 1.0);
}
.console_container .console .resultList .cmdLine span.pink {
	color: rgba(139, 38, 113, 1.0);
}
.console_container .console .resultList .cmdLine span.orange {
	color: rgba(251, 164, 20, 1.0);
}
.console_container .console .resultList .cmdLine span.black {
	max-width: 100%;
	color: rgba(19, 17, 36, 1.0);
}
.console_container .console .resultList .cmdLine span.white {
	color: rgba(226, 225, 228, 1.0);
}

.console_container .console .resultList .cmdLine span.title {
	display: block;
	position: relative;
	top: -4px;
	font-family: "NeonPixel";
	font-size: 2em;
	text-align: center;
	color: rgba(240, 55, 82, 1.0);
	line-height: 45px;
}
.console_container .console .resultList .cmdLine span.center {
	display: block;
	text-align: center;
}
.console_container .console .resultList .cmdLine span.block {
	display: inline-block;
	text-align: left;
}
.console_container .console .resultList .cmdLine span.right {
	display: block;
	text-align: right;
}
.console_container .console .resultList .cmdLine span.popup {
	cursor: pointer;
}
.console_container .console .resultList .cmdLine span.picwall {
	display: flex;
	justify-content: space-around;
	align-items: space-around;
	flex-wrap: wrap;
}
.console_container .console .resultList .cmdLine span.picwall img {
	margin: 10px;
}

.console_container .console .resultList .cmdLine span.command {
	color: rgba(167, 168, 189, 1.0);
}
.console_container .console .resultList .cmdLine span.tab {
	display: inline-block;
	width: 20px;
}
.console_container .console .resultList .cmdLine hr.command_headline {
	border-top: 1px dashed rgba(226, 225, 228, 1.0);
	border-bottom: none;
}
</style>

<script>
export default {
	name: "CmdLine",
	props: ["cmd"],
	async mounted () {
		this.$refs.contents.forEach((line, i) => {
			var ctx = this.cmd[i] || '';
			if (!!ctx.match(/^\s*-{3,}\s*$/)) {
				ctx = '<hr class="command_headline">';
			}
			else {
				let reg = new RegExp('<span class="(' + ColorfulTags.join('|') + ')">([\\w\\W]+?)<\\/span>', 'gi');
				ctx = ctx
					.replace(/\t/g, '<span class="tab"></span>')
					.replace(/\[([\w ]+?)\]\(([\w\W]+?)\)/g, (m, title, url) => {
						return '<a href="' + url + '" target="_blank">' + title + '</a>';
					})
					.replace(/\{img:([\w\W]+?)\}/gi, (m, url) => {
						var w, h;
						url = url.replace(/\|([wh])(\d+)/gi, (m, t, v) => {
							t = t.toLowerCase();
							if (t === 'w') {
								w = v * 1;
							}
							else {
								h = v * 1;
							}
							return '';
						});
						var img = '<img src="' + url + '" class="inner_image"';
						if (!!w || !!h) {
							img = img + ' style="';
							if (!!w) img = img + 'width:' + w + 'px;';
							if (!!h) img = img + 'height:' + h + 'px';
							img = img + '"'
						}
						img = img + '>';
						return img
					})
					.replace(/\{block:([\w\W]+?)\}/gi, (m, param) => {
						param = param.split(/\|+/);
						var trigger = param.shift();
						trigger = trigger.toLowerCase();
						if (['start', 'on'].includes(trigger)) {
							let css = [];
							param.forEach(p => {
								var q = p.match(/([wh])(\d+)/i);
								if (!q) return;
								var type = q[1].toLowerCase();
								if (type === 'w') {
									css.push('width:' + q[2] + 'px;');
								}
								else if (type === 'h') {
									css.push('height:' + q[2] + 'px;');
								}
							});
							
							let node = '<span class="block"';
							if (css.length > 0) {
								css = css.join('');
								node = node + ' style="' + css + '"';
							}
							node = node + '>';
							return node;
						}
						else if (['end', 'off'].includes(trigger)) {
							return '</span>';
						}
						else {
							return m;
						}
					})
					.replace(reg, (m, type, content) => {
						return '<span class="' + type + '" text="' + content + '">' + content + '</span>';
					});
				;
			}
			line.innerHTML = ctx;
			var popup = line.querySelector('span.popup');
			if (!!popup) {
				let inner = popup.innerHTML;
				let url = inner.match(/^\s*\{([^\}]+?)\}\s*/);
				if (!!url && !!url[1]) {
					popup.innerHTML = inner.replace(url[0], '');
					popup.classList.add('executable');
					popup.setAttribute('text', popup.innerText);
					popup.setAttribute('url', url[1]);
				}
			}
		});
	},
	methods: {
		onDBClick (evt) {
			var has = DBClickableList.some(tag => {
				if (evt.target.classList.contains(tag)) return true;
			});
			if (has) {
				this.$emit('choose', evt.target.innerText);
			}
		},
		onClick (evt) {
			var target = evt.target;
			var has = DBClickableList.some(tag => {
				if (target.classList.contains(tag)) return true;
			});
			if (has) {
				let isPopup = PopupTags.some(tag => {
					if (target.classList.contains(tag)) return true;
				});
				if (isPopup) {
					this.$emit('click', target.getAttribute('url'), true);
				}
				else {
					this.$emit('click', target.innerText);
				}
			}
		}
	},
};
</script>